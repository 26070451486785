import { useState } from 'react';
import './App.css';
import POSApp from './POSApp';
import { Bars3Icon, UserIcon } from '@heroicons/react/20/solid';
import { Dialog } from '@headlessui/react';

function App() {
  const cashboxid = "7b4e8331-8f7f-4a56-b105-ca1eeec9a7f2";
  const accesstoken = "BHEl92aOI7oCSJtB5zRFq6CsN13D30hkZP6ccmMg5K8cVB7ts6wgOe32drWO4tS+3uAJd9e16l7Lv0iL6y9arek=";
  const [version] = useState(0);

  let settings = {
    cashboxid: cashboxid,
    accesstoken: accesstoken
  };
  return (
    <div className="w-screen h-screen flex flex-col">
      <main className="flex justify-center overflow-auto h-full flex-col">
        <POSApp key={version} settings={settings} />
      </main>
    </div>
  );
}

export default App;
